let logoutTimer;
// const INACTIVE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
const INACTIVE_TIMEOUT = 3 * 60 * 60 * 1000; // 3시간을 밀리초로 변환

export const startLogoutTimer = (logoutCallback) => {
  clearLogoutTimer();
  logoutTimer = setTimeout(() => {
    logoutCallback();
  }, INACTIVE_TIMEOUT);
};

export const clearLogoutTimer = () => {
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
};

export const resetLogoutTimer = (logoutCallback) => {
  clearLogoutTimer();
  startLogoutTimer(logoutCallback);
};

export const setupAutoLogout = (logoutCallback) => {
  startLogoutTimer(logoutCallback);

  // Reset the timer on user activity
  const resetTimerOnActivity = () => resetLogoutTimer(logoutCallback);

  window.addEventListener('mousemove', resetTimerOnActivity);
  window.addEventListener('keypress', resetTimerOnActivity);
  window.addEventListener('click', resetTimerOnActivity);
  window.addEventListener('scroll', resetTimerOnActivity);

  // Return a cleanup function
  return () => {
    window.removeEventListener('mousemove', resetTimerOnActivity);
    window.removeEventListener('keypress', resetTimerOnActivity);
    window.removeEventListener('click', resetTimerOnActivity);
    window.removeEventListener('scroll', resetTimerOnActivity);
    clearLogoutTimer();
  };
};
