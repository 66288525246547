import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'factoryLine',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getNoPageLists(state, action) {
      state.lists = action.payload;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createFactoryLine(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateFactoryLine(state, action) {
      const updatedFactoryLine = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedFactoryLine.id ? updatedFactoryLine : item));
    },
    deleteFactoryLine(state, action) {
      const factoryLineId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== factoryLineId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const { getLists, getNoPageLists, getSingleList, createFactoryLine, updateFactoryLine, deleteFactoryLine, alertPopupToggle } =
  slice.actions;

export default slice.reducer;

export function getFactoryLineList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryLine/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getFactoryLineNoPageList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryLine/noPageList'
      };
      const response = await ApiService.get(objData);
      // console.log('factoryLine noPageList response', response.data);
      dispatch(getNoPageLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getFactoryLineInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factoryLine/factoryLineUpload`, data, config);
      if (response.status === 200) {
        dispatch(createFactoryLine(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactoryLineSingleList(factoryLineId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/factoryLine/list/single', { id: factoryLineId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getFactoryLineUpdate(NewLists, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factoryLine/factoryLineUpdate`, data, config);
      } else {
        const objData = {
          path: '/factoryLine/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateFactoryLine(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactoryLineDelete(factoryLineId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryLine/delete',
        data: { id: factoryLineId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteFactoryLine(factoryLineId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
