import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getModalLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createMaterial(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateMaterial(state, action) {
      const updatedMaterial = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMaterial.id ? updatedMaterial : item));
    },
    deleteMaterial(state, action) {
      const materialId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== materialId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getModalLists, getSingleList, createMaterial, updateMaterial, deleteMaterial } = slice.actions;

export default slice.reducer;

export function getMaterialList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/material/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMaterialModalList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/material/modalList'
      };
      const response = await ApiService.get(objData);
      // console.log(response.data);
      dispatch(getModalLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMaterialInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'm_material_inspection_item_ids') {
          // 배열 데이터를 JSON 문자열로 변환하여 전송
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/material/materialUpload`, data, config);
      if (response.status === 200) {
        dispatch(createMaterial(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMaterialSingleList(materialId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/material/list/single', { id: materialId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMaterialUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'm_material_inspection_item_ids') {
          // 배열 데이터를 JSON 문자열로 변환하여 전송
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/material/materialUpdate`, data, config);
      if (response.status === 200) {
        dispatch(updateMaterial(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

// export function getMaterialUpdate(NewLists, id, flag) {
//   return async (dispatch) => {
//     try {
//       let response;
//       if (flag === 'Y') {
//         const data = new FormData();
//         Object.keys(NewLists).forEach((key) => {
//           if (key === 'files' && NewLists[key] && NewLists[key][0]) {
//             data.append('file', NewLists[key][0]);
//           } else if (key === 'm_material_inspection_item_ids') {
//             // 배열 데이터를 JSON 문자열로 변환하여 전송
//             data.append(key, JSON.stringify(NewLists[key]));
//           } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
//             data.append(key, NewLists[key]);
//           }
//         });
//         response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/material/materialUpdate`, data, config);
//       } else {
//         const objData = {
//           path: '/material/update',
//           data: NewLists
//         };
//         response = await ApiService.post(objData);
//       }

//       if (response.status === 200) {
//         dispatch(updateMaterial(response.data));
//         return { success: true, data: response.data };
//       } else {
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//       return { success: false, error };
//     }
//   };
// }

export function getMaterialDelete(materialId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/material/delete',
        data: { id: materialId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteMaterial(materialId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
